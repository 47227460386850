import React from "react";
import { SEO } from '../../components/seo';

import Container from 'react-bootstrap/Container';

import { ArrowUpCircleFill } from 'react-bootstrap-icons';
import { Download } from 'react-bootstrap-icons';

//images
import tamat from "../../assets/case-study/tamat/mockups-1.jpg";
import dualDevices from "../../assets/case-study/tamat/dual-devices.jpg";
import foodora from "../../assets/case-study/tamat/foodora.png";
import godsmak from "../../assets/case-study/tamat/godsmak.png";
import ubereats from "../../assets/case-study/tamat/uber-eats.png";
import wolt from "../../assets/case-study/tamat/wolt.png";
import flowchart from "../../assets/case-study/tamat/flow-chart.png";
import wireframes1 from "../../assets/case-study/tamat/wireframes-1.jpg";
import wireframes2 from "../../assets/case-study/tamat/wireframes-2.jpg";
import wireframes3 from "../../assets/case-study/tamat/wireframes-3.jpg";
import colourpalette from "../../assets/case-study/tamat/colour-palette.png";
import searchbar from "../../assets/case-study/tamat/search-bar.png";
import hifi1 from "../../assets/case-study/tamat/hifi-designs-1.jpg";
import hifi2 from "../../assets/case-study/tamat/hifi-designs-2.jpg";
import hifi3 from "../../assets/case-study/tamat/hifi-designs-3.jpg";
import alignment from "../../assets/case-study/tamat/alignment.png";
import prototype from "../../assets/case-study/tamat/prototype.jpg";
import studyresults from "../../assets/case-study/tamat/study-results.jpg";
import prototypeupdate from "../../assets/case-study/tamat/prototype-update.jpg";
import secondprototypeupdate from "../../assets/case-study/tamat/second-prototype-update.jpg";
import accessibility from "../../assets/case-study/tamat/accessibility-check.png";
import mockup2 from "../../assets/case-study/tamat/mockups-5.jpg";

import hemkort from "../../assets/work/hemkort-website.jpg";

const Tamat = () => {
    const downloadCaseStudy = () => {
        fetch('../../downloads/tamat-case-study.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'tamat-case-study.pdf';
                alink.click();
            })
        })
    }
    return (
        <>
            <SEO
                title="Tamat Case Study - VishalHaria.net"
                description="UI/UX case study for Tamat - a responsive website for a restaurant food delivery startup in Sweden."
                image={tamat}
                url="https://vishalharia.net/case-study/tamat"
            />

            <Container fluid className="tamat px-0 js-scroll scrolled fade-in">
                <div className="d-flex justify-content-center">
                    <div className="cs-container position-relative">

                        <div id="topButton" className="position-fixed bottom-0 start-50 translate-middle-x top-button">
                            <ArrowUpCircleFill />
                        </div>

                        <img src={tamat} className="img-fluid" alt="Tamat responsive website" />

                        <section className="mb-5">
                            <h1>Tamat</h1>                            

                            <span className="larger-text">A restaurant food delivery website for a startup in Sweden</span>

                            <div className="cs-badges">
                                <span className="cs-badge">Competitive analysis</span>
                                <span className="cs-badge">Wireframes</span>
                                <span className="cs-badge">Flow diagrams</span>
                                <span className="cs-badge">High-fidelity UI</span>
                                <span className="cs-badge">Prototype</span>
                                <span className="cs-badge">Mini-usability study</span>
                            </div>

                            <div className="row cs-project-details">
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Where</dt>
                                        <dd>Bangkok, Thailand</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>What</dt>
                                        <dd>Responsive website</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Why</dt>
                                        <dd>Concept for a startup</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>Designer, researcher</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Category</dt>
                                        <dd>Food, restaurant</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>When</dt>
                                        <dd>Sept 2022</dd>
                                    </dl>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-5 offset-lg-1 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                        <dl>
                                            <dt>Why I made this project</dt>
                                            <dd className="larger-text mt-1">
                                            A startup in Sweden contacted me asking to design a responsive website for their food delivery business.
                                            </dd>
                                            <dd className="larger-text">
                                            They only planned on delivering food from a small number of restaurants they are partnered with.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <img src={dualDevices} className="img-fluid js-scroll slide-right mt-4 mt-md-0" alt="Dual devices" />
                                </div>
                            </div>
                        </section>

                        <section className="update-color-bg">
                            <div className="row cs-project-details pb-3">
                                <div className="col-md-6">
                                    <h2>Update (Feb 2023)</h2>
                                    <p className="larger-text mt-4 text-black">
                                        Since completing this case study, I have begun developing the website for the startup company, which has since 
                                        changed their name to "Hemkört".
                                    </p>
                                    <p className="larger-text mt-4 text-black">
                                        The website is built using HTML, CSS, JavaScript, Tailwind CSS, and PHP/Laravel.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img src={hemkort} className="img-fluid mt-0 mt-md-5 mb-4 js-scroll fade-in rounded shadow" alt="Hemkort website" />
                                </div>
                            </div>
                        </section>

                        <section className="color-bg pb-5">
                            <h2>Competitive analysis</h2>                    
                            <p className="larger-text mt-4">
                                Tamat had already conducted their own market research and claimed that in the area they planned on operating in, 
                                there was an absence of food delivery services, especially for the restaurants they partnered with. They 
                                believed that while these restaurants were very successful, having a dedicated food delivery service would 
                                be greatly beneficial.
                            </p>                        
                            <p className="larger-text mt-4">
                                I decided to conduct a competitive analysis of existing food delivery websites in Sweden to discover any 
                                successful trends or existing pain points that could be improved upon. I found 4 of the most popular 
                                competing businesses to analyse. 
                            </p>
                            <div className="row text-center pt-5">
                                <div className="col-6 js-scroll fade-in-bottom">
                                    <img src={godsmak} className="img-fluid w-100 rounded shadow" alt="Godsmak" />
                                    <p className="small mt-1">Godsmak</p>
                                </div>
                                <div className="col-6 js-scroll fade-in-bottom delay-1">
                                    <img src={wolt} className="img-fluid w-100 rounded shadow" alt="Wolt" />
                                    <p className="small mt-1">Wolt</p>
                                </div>
                                <div className="col-6 js-scroll fade-in-bottom delay-2">
                                    <img src={foodora} className="img-fluid w-100 rounded shadow" alt="Foodora" />
                                    <p className="small mt-1">Foodora</p>
                                </div>
                                <div className="col-6 js-scroll fade-in-bottom delay-3">
                                    <img src={ubereats} className="img-fluid w-100 rounded shadow" alt="Uber Eats" />
                                    <p className="small mt-1">Uber Eats</p>
                                </div>
                            </div>

                            <div className="row cs-project-details mt-5">
                                <div className="col-md-6">
                                    <dl>
                                        <dt>The good</dt>
                                        <dd>
                                            All websites have great visual styles and branding. Their navigation and user flows are quite 
                                            similar and allow users to either browse for food by category or search by text.
                                        </dd>
                                    </dl>   
                                </div>
                                <div className="col-md-6">
                                    <dl>
                                        <dt>The bad</dt>
                                        <dd>
                                            While each site featured plenty of restaurants and options for the user, it can be a bit 
                                            overwhelming as there was usually a large number of restaurants and categories to choose from.
                                        </dd>
                                        <dd>
                                            Godsmak had a very large list of restaurants and used single rows for each one, resulting in 
                                            lots of scrolling. Wolt had a large number of categories, some sharing the same restaurants, 
                                            making it easy to get lost.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <h3 className="mt-3">Problems from the reviews</h3>
                            <div className="row pb-3">
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            The driver couldn't find my house so we spent a lot of time on the phone with him trying to direct him.
                                            </dd>
                                            <dt>Sam</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            Almost every time I've used this company, my food is late and the food arrives cold.
                                            </dd>
                                            <dt>Joakim</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            My delivery never arrived and I was waiting for over an hour. Their website clearly doesn't work!
                                            </dd>
                                            <dt>Malcolm</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="row my-5">
                                <div className="col-md-8 offset-md-2">                                
                                    <div className="h-100 d-flex align-items-center">
                                        <dl className="mt-3 mb-0">
                                            <dt>Initial research shows</dt>
                                            <dd className="larger-text mt-1">
                                                The research needed for this project wasn't very extensive, but it can be said that while existing 
                                                food delivery services exist and work, they could be improved by simplifying the UI and making 
                                                sure the delivery process is reliable.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="color-bg mt-3 pb-5">
                            <h2>Flow diagram</h2>
                            <p className="larger-text mt-4">
                                I created a simple flow diagram of the main user flows of the website. Fail state flows were also created, but are 
                                not included here due to space constraints. Below is the main user flow of the website.
                            </p>

                            <img src={flowchart} className="img-fluid mt-5 mb-4" alt="Flow chart" />
                        </section>
                        
                        <section>
                            <h2>Low-fidelity wireframes</h2>
                            <p className="larger-text mt-4 pb-5">
                                Once the user flows were completed, I began designing the low-fidelity wireframes. Since I was designing a 
                                responsive website for Tamat, I took a desktop-first approach and made wireframes for a large screen which 
                                I could later adjust and modify for smaller screens.
                            </p>
                            <img src={wireframes1} className="img-fluid mb-3 js-scroll fade-in-bottom" alt="Wireframes 1" />
                            <img src={wireframes2} className="img-fluid mb-3 js-scroll fade-in-bottom" alt="Wireframes 2" />
                            <p className="larger-text my-5">
                                After completing the wireframes for larger screen sizes, they were adjusted for tablet and mobile screen 
                                sizes to be fully responsive.
                            </p>
                            <img src={wireframes3} className="img-fluid mb-5 js-scroll fade-in-bottom" alt="Wireframes 3" />
                        </section>

                        <section className="color-bg mt-3 pb-4">
                            <h2>High-fidelity UI design</h2>
                            <p className="larger-text mt-4">
                                After the low-fidelity wireframes were completed, I then proceeded to convert them into high-fidelity 
                                designs for each screen size, first deciding on the colours and fonts to use throughout the site.
                            </p>
                            <div className="row cs-project-details mt-5">
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Colour palette</dt>
                                        <dd>
                                            Primary, secondary, tertiary, accent, background
                                        </dd>
                                    </dl>   
                                    <img src={colourpalette} className="img-fluid mb-5" alt="Colour palette" />
                                </div>
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Font</dt>
                                        <dd>
                                            Plus Jakarta Sans<br />
                                            Regular, semi-bold, bold, extra-bold
                                        </dd>
                                    </dl>   
                                    <h2 className="pt-0 mb-5 mb-md-0">AaBbCcDdEeFfGgHh</h2>
                                </div>
                                <div className="col-md-6">                                
                                    <dl>
                                        <dt>Rounded corners and shadows</dt>
                                        <dd>
                                            We wanted this website to have a modern and friendly aesthetic, so I used a theme of 
                                            rounded corners for dialogs and content boxes, and light shadows to add some depth.
                                        </dd>
                                    </dl>   
                                </div>
                                <div className="col-md-6">
                                    <img src={searchbar} className="img-fluid pb-4" alt="Search bar" />
                                </div>
                            </div>
                        </section>
                        
                        <section>
                            <h2>28 high-fidelity designs</h2>
                            <p className="larger-text mt-4 pb-4">
                                A total of 28 high-fidelity designs were created for larger screens, medium-sized screens for tablets, 
                                and smaller screens for mobile devices. Since this was a responsive website, the layout was kept as 
                                similar as possible across different screen sizes for consistency, with minor changes to the layout 
                                whenever necessary.
                            </p>
                        </section>
                        <img src={hifi1} className="img-fluid my-4 js-scroll fade-in-bottom" alt="High-fidelity designs 1" />
                        <img src={hifi2} className="img-fluid my-4 js-scroll fade-in-bottom" alt="High-fidelity designs 2" />
                        <img src={hifi3} className="img-fluid mt-4 mb-5 pb-4 js-scroll fade-in-bottom" alt="High-fidelity designs 3" />

                        <section className="color-bg mt-3 pb-4">
                            <h2>Alignment and grid</h2>
                            <p className="larger-text mt-4">
                                I used a 12 column grid for larger screens, 8 columns for medium-sized screens, and 4 columns for 
                                smaller screens. I set the margins within groups to be 6, 12 and 18, while the margins between 
                                groups to be 24, 36 and 48.
                            </p>
                            <img src={alignment} className="img-fluid my-5" alt="Alignment and grid" />
                        </section>
                        
                        <section>
                            <h2>High-fidelity prototype</h2>
                            <p className="larger-text mt-4">
                                I built a clickable, high-fidelity prototype for the larger screen version of the website by connecting 
                                the screens of the main user flow. I used this prototype to test it on a sample of users with the help 
                                of the Tamat team.
                            </p>
                            <img src={prototype} className="img-fluid my-4 js-scroll fade-in-bottom" alt="Prototype" />
                            <p className="larger-text pb-4">
                                The prototype can be live-previewed by clicking <a href="https://xd.adobe.com/view/8192c0dd-e074-4c83-9048-1b05d51ae436-d4c1/" target="_blank" rel="noreferrer">here</a>.
                            </p>

                            <h2>Mini-usability study</h2>
                            <p className="larger-text mt-4">
                                Because the Tamat team did their own research for this project, they decided it would be much easier for 
                                them to conduct the usability study since they knew how to get in contact with the participants, plus I 
                                don't speak any Swedish! They informed me that they had 14 participants for the study, from varying age 
                                groups and backgrounds.
                            </p>
                            <p className="larger-text">
                                My role was to prepare the high-fidelity prototype for the users to test, state the task the user was 
                                to attempt, and provide a list of questions to ask the users in order to assess how easy or difficult 
                                they found the process. 
                            </p>
                            <p className="larger-text">
                                The task that each user was asked to complete was the main user flow of selecting a restaurant, adding 
                                a food item to their cart, and completing their order.
                            </p>
                            
                            <h2 className="pt-5">Study results</h2>
                            <p className="larger-text mt-4">
                                A whopping 79% (11 out of 14) had difficulty knowing what to do after adding a food item to their cart. 
                                The overall feedback was that after adding it to their cart, they were returned back to the previous 
                                screen and didn't know what to do. 
                            </p>
                            <p className="larger-text">
                                Even though a number appeared within the cart button in the navigation bar, it wasn't obvious enough 
                                for them to notice. Some users managed to figure out how to proceed after a while, but this still 
                                proved to be a pain point that needed fixing.
                            </p>
                            <img src={studyresults} className="img-fluid mt-4 js-scroll fade-in-bottom" alt="Study results" />

                            <h2 className="mt-4">Prototype update</h2>
                            <p className="larger-text mt-4">
                                After receiving the feedback from the study, the cart button was modified so that after items are 
                                added to the cart, it changed colour - the background turned to the main CTA colour while the icons 
                                and text turned white. A simple animation was also added every time a new item is added to the cart, 
                                to help grab the user's attention to it.
                            </p>
                            <img src={prototypeupdate} className="img-fluid mt-4 js-scroll fade-in-bottom" alt="Prototype update" />

                            <h2 className="mt-4">Another round of testing</h2>
                            <p className="larger-text mt-4">
                                Because the first round of testing had a very poor success rate regarding the cart button, it was 
                                important we did another round to see if the changes I made helped improve the user experience. I 
                                was informed that the participants would be split into two groups - one contained users who were 
                                in the first round of testing, and the other was a new group of users - for a total of 14 users again.
                            </p>
                            <p className="larger-text">
                                The success rate was much better this time around with 86% of users (12 out of 14) completing the flow 
                                successfully and without issues. The remaining 2 users stated that they felt they should be given the 
                                option to go straight to the checkout after adding an item to their cart, which is a common pattern used 
                                in many e-commerce websites. 
                            </p>

                            <h2 className="pt-5">Second prototype update</h2>
                            <p className="larger-text mt-4">
                                With the feedback from the second study, I decided to add a "Buy now" button to the food item dialog 
                                that takes the user straight to the checkout rather than making them have to go through the cart flow. 
                                This way, users have the choice to either add more items to their cart or buy quickly - addressing the 
                                issue a few users had in the study.
                            </p>
                            <img src={secondprototypeupdate} className="img-fluid mt-4 mb-5 js-scroll fade-in-bottom" alt="Second prototype update" />
                        </section>

                        <div className="color-bg mt-3 mb-4 pb-0"> 
                            <section>
                                <h2>Accessibility check</h2>
                                <p className="larger-text mt-4">
                                    Accessibility was a priority when creating this website especially since the target audience includes 
                                    elderly people who might suffer from poor vision. It was evaluated for contrast to pass the AA 
                                    standards of the WCAG. Initially, there was a major issue with contrast as the colour theme was 
                                    very different from the final product.
                                </p>
                            </section>                      
                            <img src={accessibility} className="img-fluid mt-4" alt="Accessibility check" />
                        </div>

                        <section className="pb-5 mb-3">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">     
                                    <dl className="mt-3 mb-3">
                                        <dt>Project summary</dt>
                                        <dd className="larger-text mt-1">
                                            My roles for this project were to conduct a competitive analysis, create low-fidelity 
                                            wireframes and user flows, build high-fidelity designs for different screen sizes, and 
                                            build a working high-fidelity prototype. 
                                        </dd>

                                        <dd className="larger-text">
                                            A few significant changes were made throughout the design process, including changing 
                                            the main colour theme to meet WCAG standards, and giving the user more flow options 
                                            based on the usability study findings.
                                        </dd>

                                        <dd className="larger-text">
                                            I found this project interesting because the concept is simple and even though there 
                                            are already many apps and websites that offer the same services, I enjoyed creating 
                                            a design from my own knowledge and experience of UI/UX for a startup starting only 
                                            with a few restaurants. I'm excited for the future if they decide to expand and 
                                            offer more services or add additional functionality to their website - or even 
                                            create native mobile apps!
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </section>

                        <img src={mockup2} className="img-fluid" alt="Tamat mockups" />

                        <div className="text-center py-5">
                            <button onClick={downloadCaseStudy} className="btn btn-primary btn-blue">Download PDF <Download className="ms-1" /></button>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    );
};

export default Tamat;
