import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import TopMenu from '../components/topmenu';
import Footer from '../components/footer';

const Layout = () => {

    //const [offset, setOffset] = useState(0);

    useEffect(() => {

        const topButton = document.querySelector("#topButton");
        if(topButton){
            topButton.addEventListener("click", ()=> {
                let top = document.querySelector('#topMenu').getBoundingClientRect().top;
                window.scrollTo( { top: top, behavior: 'smooth' } );
            });
        }

        const links = document.querySelectorAll("[data-part1][data-part2][data-part3]");
        for (const link of links) {
        const attrs = link.dataset;
        link.setAttribute(
            "href",
            `mailto:${attrs.part1}@${attrs.part2}.${attrs.part3}?subject=${attrs.subject}`
        );
        }
    
        const scrollOffset = 100;
     
        const scrollElements = document.querySelectorAll(".js-scroll");
     
        const elementInView = (el, offset = 0) => {
            const elementTop = el.getBoundingClientRect().top;
            
            return (
                elementTop <= 
                ((window.innerHeight || document.documentElement.clientHeight) - offset)
            );
        };
     
        const displayScrollElement = (element) => {
            element.classList.add('scrolled');
        }
         
        const hideScrollElement = (element) => {
            element.classList.remove('scrolled');
        }
         
        const handleScrollAnimation = () => {
            scrollElements.forEach((el) => {
                if (elementInView(el, scrollOffset)) {
                    displayScrollElement(el);
                } else {
                    hideScrollElement(el);
                }
            })
        }
    
        let throttleTimer = false;
         
        const throttle = (callback, time) => {
            //don't run the function while throttle timer is true
            if (throttleTimer) return;
             
            //first set throttle timer to true so the function doesn't run
            throttleTimer = true;
             
            setTimeout(() => {
                //call the callback function in the setTimeout and set the throttle timer to false after the indicated time has passed 
                callback();
                throttleTimer = false;
            }, time);
        }
        
        const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
        /*
        const onScroll = () => setOffset(window.pageYOffset);
        */      
        const onScroll = () => {
            if (mediaQuery && !mediaQuery.matches) {
                throttle(handleScrollAnimation, 250);
            }
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <Container fluid className="main-container">
                <TopMenu />
            </Container>
            <Outlet />
            <Footer />
        </>
    );
};

export default Layout;