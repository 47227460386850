import React from "react";
import { SEO } from '../components/seo';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

import { ArrowUpCircleFill } from 'react-bootstrap-icons';
import { Download } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

import ModalImage from "react-modal-image";

import profilepic from "../assets/vishal-haria.jpg";
import winnie from '../assets/about/murials/winnie-pooh.jpg';
import aladdin from '../assets/about/murials/aladdin.jpg';
import anime from '../assets/about/murials/anime.jpg';
import michaeljordan from '../assets/about/murials/michael-jordan.jpg';
import cals1 from '../assets/about/calsandmacros/app-calculate.jpg';
import cals2 from '../assets/about/calsandmacros/app-food-diary.jpg';
import cals3 from '../assets/about/calsandmacros/app-food-search.jpg';
import daimonslayers from '../assets/about/nft/collage.jpg';
import certificate from '../assets/about/google-ux-design-certificate.png';

import ogimage from "../assets/vishalharia-website-screenshot.jpg";

const About = () => {
    
    const downloadResume = () => {
        fetch('../downloads/vishal-haria-resume.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'vishal-haria-resume.pdf';
                alink.click();
            })
        })
    }
    return (
        <>        
            <SEO
                title="About Vishal Haria - VishalHaria.net"
                description="Find out a little more about Vishal Haria, what other skills and interests he has and a few of his accomplishments."
                image={ogimage}
                url="https://vishalharia.net/about"
            />

            <Container fluid className="px-0 py-5">
                <div className="d-flex justify-content-center">
                    <div className="about-container position-relative">
                        <div id="topButton" className="position-fixed bottom-0 start-50 translate-middle-x top-button">
                            <ArrowUpCircleFill />
                        </div>

                        <section className="px-3 px-md-auto">
                            <Row>
                                <Col md={4} className="d-flex justify-content-center justify-content-md-end align-items-center">
                                    <img src={profilepic} className="rounded-circle profile-pic mb-4 mb-md-0 shadow" alt="Vishal Haria" />
                                </Col>
                                <Col>
                                    <h2 className="pt-0 mb-4">Hi, I'm Vishal!</h2>                                    
                                    <p>
                                        I'm a UI/UX designer born and raised in the UK currently living in Bangkok, Thailand. I specialize in 
                                        conceptualizing and designing beautiful digital products, and also have a background in web development 
                                        and video production.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md={4} className="d-none d-md-block">
                                    <Row className="about-gallery shadow">
                                        <Col xs={3}><ModalImage small={winnie} large={winnie} hideDownload={true} hideZoom={true} alt="Winnie the Pooh" className="about-img" /></Col>
                                        <Col xs={3}><ModalImage small={aladdin} large={aladdin} hideDownload={true} hideZoom={true} alt="Aladdin" className="about-img" /></Col>
                                        <Col xs={3}><ModalImage small={anime} large={anime} hideDownload={true} hideZoom={true} alt="Anime" className="about-img" /></Col>
                                        <Col xs={3}><ModalImage small={michaeljordan} large={michaeljordan} hideDownload={true} hideZoom={true} alt="Michael Jordan" className="about-img" /></Col>
                                    </Row>

                                    <div className="youtube">
                                        <iframe 
                                            width="100%" 
                                            height="100%" 
                                            src="https://www.youtube.com/embed/rHSIMcIjSLg?controls=0" 
                                            title="YouTube video player" 
                                            frameborder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowfullscreen>
                                        </iframe>
                                    </div>

                                    <Row className="about-gallery shadow">
                                        <Col xs={4}><ModalImage small={cals1} large={cals1} hideDownload={true} hideZoom={true} alt="Cals and Macros - Calculate" className="about-img" /></Col>
                                        <Col xs={4}><ModalImage small={cals2} large={cals2} hideDownload={true} hideZoom={true} alt="Cals and Macros - Food diary" className="about-img" /></Col>
                                        <Col xs={4}><ModalImage small={cals3} large={cals3} hideDownload={true} hideZoom={true} alt="Cals and Macros - Food search" className="about-img" /></Col>
                                    </Row>

                                    <ModalImage small={daimonslayers} large={daimonslayers} hideDownload={true} hideZoom={true} alt="NFT collection" className="about-img shadow" />

                                    <a href="https://www.coursera.org/account/accomplishments/professional-cert/5J9FC62G99KC" target="_blank" rel="noreferrer">
                                        <img src={certificate} className="img-fluid" />
                                    </a>
                                </Col>
                                <Col>
                                    <p>
                                        Ever since I was young, I loved designing things and being creative. Painting murials in children's 
                                        bedrooms was my first real job which I did during high school and college. I also loved drawing 
                                        real-life people and anime.
                                    </p>
                                    <div className="d-block d-md-none">
                                        <Row className="about-gallery-md shadow">
                                            <Col xs={3}><ModalImage small={winnie} large={winnie} hideDownload={true} hideZoom={true} alt="Winnie the Pooh" className="about-img" /></Col>
                                            <Col xs={3}><ModalImage small={aladdin} large={aladdin} hideDownload={true} hideZoom={true} alt="Aladdin" className="about-img" /></Col>
                                            <Col xs={3}><ModalImage small={anime} large={anime} hideDownload={true} hideZoom={true} alt="Anime" className="about-img" /></Col>
                                            <Col xs={3}><ModalImage small={michaeljordan} large={michaeljordan} hideDownload={true} hideZoom={true} alt="Michael Jordan" className="about-img" /></Col>
                                        </Row>
                                    </div>
                                    <p>
                                        I've always been hugely interested in design, both visually and logically. I love being able to make 
                                        something that looks great, but also creating solutions with practical uses.
                                    </p>
                                    <p>
                                        I studied Multimedia Computing at the University of Westminster in London which included topics such 
                                        as digital animation, computer programming, web development and user interaction - a bachelor's 
                                        degree well suited to me and my interests!
                                    </p>
                                    <p>
                                        After graduating from university and moving to Thailand, I worked in various industries with different 
                                        roles - a webmaster for real estate companies, a freelance web developer, a freelance videographer, 
                                        owning a personal training business, and a UI/UX designer. While these roles and industries vary (in 
                                        some cases by quite a lot), they each share a common trait - they allowed me to be <strong>creative and design</strong>.
                                    </p>

                                    <div className="d-block d-md-none youtube-md">
                                        <iframe 
                                            width="100%" 
                                            height="100%" 
                                            src="https://www.youtube.com/embed/rHSIMcIjSLg?controls=0" 
                                            title="YouTube video player" 
                                            frameborder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowfullscreen>
                                        </iframe>
                                    </div>

                                    <p>
                                        I truly believe that when we <em>really</em> put our minds to something, <strong>we can achieve anything</strong>. I'm very 
                                        passionate about fitness, so in 2012 I learned enough Objective C and Java to create my own nutrition 
                                        tracking app for iOS and Android - it was called Cals and Macros. I knew nothing about native mobile app 
                                        development but I wanted to do it, so I did!
                                    </p>
                                    <div className="d-block d-md-none">
                                        <Row className="about-gallery-md shadow">
                                            <Col xs={4}><ModalImage small={cals1} large={cals1} hideDownload={true} hideZoom={true} alt="Cals and Macros - Calculate" className="about-img" /></Col>
                                            <Col xs={4}><ModalImage small={cals2} large={cals2} hideDownload={true} hideZoom={true} alt="Cals and Macros - Food diary" className="about-img" /></Col>
                                            <Col xs={4}><ModalImage small={cals3} large={cals3} hideDownload={true} hideZoom={true} alt="Cals and Macros - Food search" className="about-img" /></Col>
                                        </Row>
                                    </div>
                                    <p>
                                        In 2021, during the exploding growth of cryptocurrencies and NFT's (non-fungible tokens), I had a 
                                        curiousity about NFT's so I decided to make my own collection of 10,000 unique, randomly generated 
                                        pieces of art. I created my designs in Illustrator, coded the contracts in Solidity, wrote a script in 
                                        PHP to combine layers and produce the final images, and built a Web3 website in React that allowed 
                                        users to mint their NFT's directly from the contract.
                                    </p>
                                    <div className="d-block d-md-none about-gallery-md shadow">
                                        <ModalImage small={daimonslayers} large={daimonslayers} hideDownload={true} hideZoom={true} alt="NFT collection" className="about-img" />
                                    </div>
                                    <p>
                                        As of now, my primary focus is UI/UX as I believe it is at the core center of everything else that I 
                                        do and have passions for. I continue learning and updating my skills and experiences, and I believe the 
                                        future holds much in store for myself and the industry.
                                    </p>
                                    
                                    <Button variant="outline-primary" onClick={downloadResume}>
                                        Download resume <Download className="ms-1" />
                                    </Button>

                                    <a href="https://www.coursera.org/account/accomplishments/professional-cert/5J9FC62G99KC" target="_blank" rel="noreferrer"className="d-block d-md-none text-center">
                                        <img src={certificate} className="img-fluid" />
                                    </a>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default About;
