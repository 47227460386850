import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-loading";
import Layout from './pages/layout';
import Index from './pages';
import About from './pages/about';
import Synrgetic from './pages/case-study/synrgetic';
import Metamask from './pages/case-study/metamask';
import Tamat from './pages/case-study/tamat';
import Larder from './pages/case-study/larder';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

    const helmetContext = {};
  
    return (
        <HelmetProvider context={helmetContext}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route index element={<Index />} />
                        <Route path='about' element={<About/>} />
                        <Route path='case-study/synrgetic' element={<Synrgetic/>} />
                        <Route path='case-study/metamask-apple-watch' element={<Metamask/>} />
                        <Route path='case-study/tamat' element={<Tamat/>} />
                        <Route path='case-study/larder' element={<Larder/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;
