import React from "react";
import { SEO } from '../../components/seo';

import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { ArrowUpCircleFill } from 'react-bootstrap-icons';
import { Download } from 'react-bootstrap-icons';

//images
import instagramIcon from "../../assets/instagram-icon.png";
import synrgetic from "../../assets/case-study/synrgetic/mockups-1.jpg";
import dualDevices from "../../assets/case-study/synrgetic/dual-devices.jpg";
import researchChart from "../../assets/case-study/synrgetic/research-chart.png";
import truecoach from "../../assets/case-study/synrgetic/truecoach_purple.png";
import ptdistinction from "../../assets/case-study/synrgetic/pt-distinction.png";
import mypthub from "../../assets/case-study/synrgetic/my-pt-hub.png";
import trainerize from "../../assets/case-study/synrgetic/trainerize.png";
import initialresearch from "../../assets/case-study/synrgetic/initial-research.jpg";
import persona1 from "../../assets/case-study/synrgetic/persona-1.jpg";
import persona2 from "../../assets/case-study/synrgetic/persona-2.jpg";
import designprocess from "../../assets/case-study/synrgetic/design-process.jpg";
import flowchart from "../../assets/case-study/synrgetic/flow-chart.png";
import wireframes from "../../assets/case-study/synrgetic/wireframes.jpg";
import colourpalette from "../../assets/case-study/synrgetic/colour-palette.png";
import frostedglass from "../../assets/case-study/synrgetic/frosted-glass.png";
import hifi from "../../assets/case-study/synrgetic/high-fidelity-designs.jpg";
import alignment from "../../assets/case-study/synrgetic/alignment.png";
import prototype from "../../assets/case-study/synrgetic/prototype.jpg";
import studyresults from "../../assets/case-study/synrgetic/study-results.jpg";
import prototypeupdate from "../../assets/case-study/synrgetic/prototype-update.jpg";
import accessibility from "../../assets/case-study/synrgetic/accessibility-check.png";
import mockup2 from "../../assets/case-study/synrgetic/mockups-2.jpg";

import synrgeticFlutterApp from "../../assets/work/workouts-mockups.png";

const Synrgetic = () => {
    const downloadCaseStudy = () => {
        fetch('../../downloads/synrgetic-case-study.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'synrgetic-case-study.pdf';
                alink.click();
            })
        })
    }
    return (
        <>
            <SEO
                title="Synrgetic Case Study - VishalHaria.net"
                description="UI/UX case study for Synrgetic - a workout and progress tracking mobile app for users and coaches."
                image={synrgetic}
                url="https://vishalharia.net/case-study/synrgetic"
            />
            
            <Container fluid className="synrgetic px-0 js-scroll scrolled fade-in">
                <div className="d-flex justify-content-center">
                    <div className="cs-container position-relative">

                        <div id="topButton" className="position-fixed bottom-0 start-50 translate-middle-x top-button">
                            <ArrowUpCircleFill />
                        </div>

                        <img src={synrgetic} className="img-fluid" alt="Synrgetic mobile app" />

                        <section className="mb-5">
                            <h1>Synrgetic</h1>                            

                            <span className="larger-text">A workout and progress tracking app for users and coaches</span>

                            <div className="cs-badges">
                                <span className="cs-badge">Market research</span>
                                <span className="cs-badge">Competitive analysis</span>
                                <span className="cs-badge">User survey</span>
                                <span className="cs-badge">Personas</span>
                                <span className="cs-badge">Wireframes</span>
                                <span className="cs-badge">Flow diagrams</span>
                                <span className="cs-badge">High-fidelity UI</span>
                                <span className="cs-badge">Prototype</span>
                                <span className="cs-badge">Mini-usability study</span>
                            </div>

                            <div className="row cs-project-details">
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Where</dt>
                                        <dd>Bangkok, Thailand</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>What</dt>
                                        <dd>Native mobile app</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Why</dt>
                                        <dd>Portfolio project / concept</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>Designer / researcher</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Category</dt>
                                        <dd>Fitness / social</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>When</dt>
                                        <dd>Jan 2022 - Mar 2022</dd>
                                    </dl>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-5 offset-lg-1 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                        <dl>
                                            <dt>Why I made this project</dt>
                                            <dd className="larger-text mt-1">
                                                Fitness is a passion of mine. This app lets users track their workouts and progress, 
                                                have a coach program their workouts, plus interact with others within the app.
                                            </dd>
                                            <dd className="larger-text">
                                                It is a concept based on Synrgetic's existing website which can be found <a href="https://synrgetic.com" target="_blank" rel="noreferrer">here</a>.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <img src={dualDevices} className="img-fluid mt-4 mt-md-0 js-scroll slide-right" alt="Dual devices" />
                                </div>
                            </div>
                        </section>

                        <section className="update-color-bg">
                            <div className="row cs-project-details pb-3">
                                <div className="col-md-6">
                                    <h2>Update (July 2023)</h2>
                                    <p className="larger-text mt-4 text-black">
                                        Since completing this case study and further iterations of the app's design, I have completed the development of 
                                        the app using Flutter. It has been released natively on iOS and Android in the Apple App and Google Play 
                                        stores respectively.
                                    </p>
                                    <p className="larger-text mt-4 text-black">
                                        I also designed and developed the app's website that includes download links for the app. 
                                        <a href="https://synrgetic.com" target="_blank" rel="noreferrer">Click here to visit the website.</a>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img src={synrgeticFlutterApp} className="img-fluid mt-0 mt-md-5 mb-4 js-scroll fade-in" alt="Synrgetic - Flutter App" />
                                </div>
                            </div>
                        </section>

                        <section className="color-bg">
                            <div className="row cs-project-details pb-3">
                                <div className="col-md-6">
                                    <h2>Market research</h2>
                                    <dl className="mt-4">
                                        <dt>The claim</dt>
                                        <dd>
                                        Fitness and workout tracking apps have seen tremendous growth over the past several years. 
                                        The market is estimated to be around $5.35B worldwide.
                                        </dd>
                                    </dl>                            
                                    
                                    <dl>
                                        <dt>The problem</dt>
                                        <dd>
                                            Many existing apps either lack a lot of useful features, or are expensive or difficult to use by a lot of users.
                                        </dd>
                                    </dl>
                                </div>
                                <div className="col-md-6">
                                    <img src={researchChart} className="img-fluid mt-0 mt-md-5 mb-5 js-scroll fade-in" alt="Market research" />
                                </div>
                            </div>
                        </section>

                        <section className="pb-4">
                            <h2>Competitive analysis</h2>                    
                            <p className="larger-text mt-4">
                                I analysed 4 of the most popular fitness coaching and workout tracking apps, taking note of the reviews in the app stores, 
                                in order to find patterns.
                            </p>
                            <div className="row cs-project-details mt-5">
                                <div className="col-md-6 mb-5 mb-md-0">
                                    <div className="row text-center">
                                        <div className="col-6 js-scroll fade-in-bottom">
                                            <img src={truecoach} className="img-fluid w-100 rounded shadow" alt="True Coach" />
                                            <p className="small mt-1">True Coach</p>
                                        </div>
                                        <div className="col-6 js-scroll fade-in-bottom delay-1">
                                            <img src={ptdistinction} className="img-fluid w-100 rounded shadow" alt="PT Distinction" />
                                            <p className="small mt-1">PT Distinction</p>
                                        </div>
                                        <div className="col-6 js-scroll fade-in-bottom delay-2">
                                            <img src={mypthub} className="img-fluid w-100 rounded shadow" alt="My PT Hub" />
                                            <p className="small mt-1">My PT Hub</p>
                                        </div>
                                        <div className="col-6 js-scroll fade-in-bottom delay-3">
                                            <img src={trainerize} className="img-fluid w-100 rounded shadow" alt="Trainerize" />
                                            <p className="small mt-1">Trainerize</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <dl>
                                        <dt>The good</dt>
                                        <dd>
                                        All apps let coaches create programs for their clients and track their progress over time. 
                                        </dd>
                                    </dl>                            
                                    
                                    <dl>
                                        <dt>The bad</dt>
                                        <dd>
                                        Most apps didn't allow users to track their own workouts or progress unless they had a coach. PT Distinction and 
                                        Trainerize don't offer a free payment tier, meaning users would need to pay immediately when using the app.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <h3 className="mt-4">Problems from the reviews</h3>
                            <div className="row">
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                                The interface is too complicated. It's hard to know when one function starts and another ends.
                                            </dd>
                                            <dt>Joshua</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            There are too many bugs in the recent versions - it's more hassle than helpful to use.
                                            </dd>
                                            <dt>Jolee</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            The fonts are too small and hard to read, and some pages are too long to scroll through.
                                            </dd>
                                            <dt>Peyto</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="color-bg mt-5 pb-4">
                            <h2>User survey</h2>
                            <p className="larger-text mt-4 pb-4">
                            I conducted a short survey among people who engage in fitness activities - both normal users and coaches - on social media.
                            </p>
                            <div className="row mt-4 mb-4">
                                <div className="col-1 d-none d-sm-block">
                                    <img src={instagramIcon} className="img-fluid mt-3" alt="Instagram icon" />
                                </div>
                                <div className="col-sm-11">
                                    <h2 className="pt-0">
                                        How important is it that you or your coach is able to track your workouts and progress?
                                    </h2>
                                    <p className="small mt-3">18 participants</p>

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Very important</span>
                                        <span>31%</span>
                                    </div>
                                    <ProgressBar now={31} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Quite important</span>
                                        <span>34%</span>
                                    </div>
                                    <ProgressBar now={34} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Not sure</span>
                                        <span>22%</span>
                                    </div>
                                    <ProgressBar now={22} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Not really important</span>
                                        <span>7%</span>
                                    </div>
                                    <ProgressBar now={7} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Not important at all</span>
                                        <span>6%</span>
                                    </div>
                                    <ProgressBar now={6} className="mt-2 bg-white" />
                                </div>
                            </div>
                            
                            <h3 className="pt-5">Notable comments</h3>
                            <div className="row mt-3 mb-5">
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            I just use pen and paper
                                            because it's easier and
                                            faster than using an app.
                                            </dd>
                                            <dt>Steve</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            I'm using an app at the
                                            moment but I just wish it
                                            was easier to use!
                                            </dd>
                                            <dt>Mark</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            My coach tracks
                                            everything for me so I just
                                            let her get on with it!
                                            </dd>
                                            <dt>Jennifer</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="border-bottom border-3">
                            <div className="row">
                                <div className="col-md-4 mt-4 mt-md-5 order-2 order-md-1">
                                    <img src={initialresearch} className="img-fluid js-scroll fade-in" alt="Initial research" />
                                </div>
                                <div className="col-md-8 order-1 order-md-2 mt-5 mt-md-0">                                
                                    <div className="h-100 d-flex align-items-center">
                                        <dl className="mt-3 mb-0">
                                            <dt>Initial research shows</dt>
                                            <dd className="larger-text mt-1">
                                            As more people are becoming active with fitness, there is a growing need for them or their 
                                            coaches to track exercises and workouts in an easy, reliable and convenient way.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="pt-4 pb-4 border-bottom border-3">
                            <h2>Personas</h2>
                            <p className="larger-text mt-4">
                            I created two personas based on the two types of users that will use the app: fitness enthusiasts and coaches.
                            </p>
                        </section>

                        <img src={persona1} className="img-fluid border-bottom border-3" alt="Persona 1" />

                        <img src={persona2} className="img-fluid mt-5 border-bottom border-top border-3" alt="Persona 2" />

                        <section>
                            <div className="row mt-5">
                                <div className="col-lg-5 offset-lg-1 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                    <dl>
                                        <dt>The design process</dt>
                                        <dd className="larger-text mt-1">
                                        After completing the research stage, it was time to create the first user flows and initial 
                                        low-fidelity wireframes.
                                        </dd>
                                    </dl>
            </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <img src={designprocess} className="img-fluid js-scroll slide-right" alt="Design process" />
                                </div>
                            </div>
                        </section>

                        <section className="color-bg mt-3 pb-3">
                            <h2>Flow diagram</h2>
                            <p className="larger-text mt-4">
                            I created a simple flow diagram of the main actions a user can take using the app to outline all of the 
                            necessary functionality. Fail state flows were created too, but are not shown due to space constraints. 
                            One of the main flows is shown below.
                            </p>

                            <img src={flowchart} className="img-fluid mt-5 mb-4" alt="Flow chart" />
                        </section>
                        
                        <section>
                            <h2>Low-fidelity wireframes</h2>
                            <p className="larger-text mt-4">
                            After completing the user flow diagrams, I began creating the low-fidelity wireframes of the main user flows.
                            </p>
                            <img src={wireframes} className="img-fluid mb-4 js-scroll fade-in-bottom" alt="Wireframes" />
                        </section>

                        <section className="color-bg mt-3 pb-0">
                            <h2>High-fidelity UI design</h2>
                            <p className="larger-text mt-4">
                            Once the initial flow and wireframes were completed, I defined the fonts and colours to be used, and 
                            designed the main screens of the app in high-fidelity.
                            </p>
                            <div className="row cs-project-details mt-5">
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Colour palette</dt>
                                        <dd>
                                        Primary, secondary, tertiary, accent, background, macro-nutrients
                                        </dd>
                                    </dl>   
                                    <img src={colourpalette} className="img-fluid mb-5" alt="Colour palette" />
                                </div>
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Font</dt>
                                        <dd>
                                        Inter<br />
                                        Regular, medium, semi-bold, bold
                                        </dd>
                                    </dl>   
                                    <h2 className="pt-0 mb-5 mb-md-0">AaBbCcDdEeFfGgHh</h2>
                                </div>
                                <div className="col-md-6">                                
                                    <dl>
                                        <dt>Frosted glass appearance</dt>
                                        <dd>
                                        Certain popups and dialogs have a frosted glass appearance. This was achieved by having shapes 
                                        of the app's main colours blurred in the background, and reducing the opacity.
                                        </dd>
                                    </dl>   
                                </div>
                                <div className="col-md-6">
                                    <img src={frostedglass} className="img-fluid" alt="Frosted glass" />
                                </div>
                            </div>
                        </section>
                        
                        <section>
                            <h2>32 high-fidelity designs</h2>
                            <p className="larger-text mt-4">
                            A total of 32 high-fidelity designs were created using a combination of a tab bar and a hamburger menu. 
                            Initially, just a hamburger menu was used but after testing, it was decided that having the most used 
                            menu items on a tab bar would be more efficient.
                            </p>
                        </section>
                        <img src={hifi} className="img-fluid mt-4 mb-5 js-scroll fade-in-bottom" alt="High-fidelity designs" />

                        <section className="color-bg mt-3 pb-0">
                            <h2>Alignment and grid</h2>
                            <p className="larger-text mt-4">
                            I used a 6-point grid for the app and set the margins within groups to be 6 and 18, while the margins 
                            between groups to be 24 and 36.
                            </p>
                            <img src={alignment} className="img-fluid mt-4 mb-5" alt="Alignment and grid" />
                        </section>
                        
                        <section>
                            <h2>High-fidelity prototype</h2>
                            <p className="larger-text mt-4">
                            I built a clickable, high-fidelity prototype by connecting the screens of the main user flow. This 
                            allowed me to test the app on a group of users.
                            </p>
                        </section>
                        <img src={prototype} className="img-fluid my-4 js-scroll fade-in-bottom" alt="Prototype" />

                        <section className="pb-4">
                            <p className="larger-text">
                                The prototype can be live-previewed by clicking <a href="https://xd.adobe.com/view/5f62e304-90e7-46e5-9799-f5bb5fa1fee8-0a31/?fullscreen" target="_blank" rel="noreferrer">here</a>.
                            </p>

                            <h2 className="mt-5">Mini-usability study</h2>
                            <p className="larger-text mt-4">
                            I tested my prototype with 7 users. The users were made up of 3 coaches and 4 gym users, each with 
                            varying levels of fitness experience. 
                            </p>
                            <p className="larger-text">
                            Each person was given a few tasks to complete within certain categories - creating a workout, accessing 
                            the tracking section, nutrition targets section, and exercise progress section. I wanted to be sure that 
                            each user could complete each task successfully, and that they understood that each section has its own sub-views. 
                            </p>
                            <p className="larger-text">
                                The tests were conducted via Zoom so I could introduce the app to each user in real time and ask them 
                                questions. The questions were dedicated to finding out whether the flows and views were easy enough to 
                                understand.
                            </p>

                            <div className="row mt-5">
                                <div className="col-md-4">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={studyresults} className="img-fluid my-0 js-scroll fade-in-bottom" alt="Study results" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h2>Study results</h2>
                                    <p className="larger-text mt-4">
                                    57% of the users (4 out of 7) felt that the workout details page was too long and that they had 
                                    to scroll too much. This made them feel confused about where they were on the page and added some 
                                    frustration. They were still able to complete the task but the overall feedback was that it needed 
                                    to be improved. 
                                    </p>
                                    <p className="larger-text">
                                    43% of the users (3 out of 7) were unsure how to change the date on the nutrition targets page. They 
                                    didn't realise at first that the date itself was a button that opened up a calendar widget, so 
                                    they were unable to fully complete the task - therefore the success rate for that task was just 57%.
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-8">
                                    <h2>Prototype update</h2>
                                    <p className="larger-text mt-4">
                                    Based on the feedback of the study, a couple of changes were made to the workout details page and 
                                    nutrition target page.
                                    </p>
                                    <p className="larger-text">
                                    Tabs were added to the workout details page for the sets of each exercise to greatly reduce the 
                                    amount of scrolling. 
                                    </p>
                                    <p className="larger-text">
                                    A box with a calendar button and label was added to the nutrition target page to make it clear 
                                    that it can be tapped to open the calendar widget and change the date. 
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={prototypeupdate} className="img-fluid mt-4 mt-md-2 mb-0 js-scroll fade-in-bottom" alt="Prototype update" />
                                    </div>
                                </div>
                            </div>

                            <h2 className="mt-4">Second round of testing</h2>
                            <p className="larger-text mt-4">
                            After making the changes to the prototype, the same 7 users were asked to complete the same tasks as before, 
                            following the same instructions. The results this time were much more positive than the first round, with 6 
                            out of 7 users being able to complete the tasks successfully and having a better experience doing so - 
                            meaning a success rate of 86%. 
                            </p>
                            <p className="larger-text">
                            A comment from the user who still experienced difficulties was that while they found the app a little tricky 
                            to use, they admitted they weren't very good at using technology in general. A takeaway from this would be 
                            that in future iterations of the app's design, more focus would need to be placed on accessibility, aiming 
                            to make the app even easier to use.
                            </p>
                        </section>

                        <div className="color-bg mt-3 mb-4 pb-0"> 
                            <section>
                                <h2>Accessibility check</h2>
                                <p className="larger-text mt-4">
                                To make the app accessible to all users, it was evaluated for contrast to pass the AA standards of the WCAG. 
                                It was found that there were some cases where the contrast could be improved.
                                </p>
                            </section>                      
                            <img src={accessibility} className="img-fluid mt-4" alt="Accessibility check" />
                        </div>

                        <section className="pb-5 mb-3">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">     
                                    <dl className="mt-3 mb-3">
                                        <dt>Project summary</dt>
                                        <dd className="larger-text mt-1">
                                        For this project, I did market research including a competitive analysis, a quick user survey 
                                        on social media, used the findings to create personas, created low-fidelity wireframes and user 
                                        flows, build a high-fidelity prototype and iterated through high-fidelity UI designs. Along each 
                                        stage of the design process, the goals and frustrations were kept in mind to make sure that I 
                                        was focused on solving the problem of the user.
                                        </dd>

                                        <dd className="larger-text">
                                            To finalise the designs, I checked for accessibility issues focusing on colour and contrast, 
                                            and made changes to meet the WCAG standards.
                                        </dd>

                                        <dd className="larger-text">
                                            Things I learned from this project are that apps with a lot of information and functionality 
                                            such as this one require a lot of organisation to make them feel easy to use. The layouts 
                                            need to be as easy to read and understand immediately and the flows need to be intuitive 
                                            as there are a lot of things the user can do within the app. Making the most out of the 
                                            limited screen space is important, such as reducing the need for scrolling through a large 
                                            amount of information.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </section>

                        <img src={mockup2} className="img-fluid" alt="Synrgetic mockups" />

                        <div className="text-center py-5">
                            <button onClick={downloadCaseStudy} className="btn btn-primary btn-blue">Download PDF <Download className="ms-1" /></button>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    );
};

export default Synrgetic;
