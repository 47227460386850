import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import { Behance } from 'react-bootstrap-icons';
import { Linkedin } from 'react-bootstrap-icons';
import { Dribbble } from 'react-bootstrap-icons';
import { Twitter } from 'react-bootstrap-icons';
import { Medium } from 'react-bootstrap-icons';
import { EnvelopeFill } from 'react-bootstrap-icons';

const Footer = () => {
    return (
        <Container fluid className="footer">
            <Row>
                <Col className="get-in-touch text-center text-md-start" md={6}>
                    <h2>Get in touch</h2>
                    <a className="btn btn-primary mt-1" href="" data-part1="contact" data-part2="vishalharia" data-part3="com" data-subject="Hello%20Vishal!">contact@vishalharia.com <EnvelopeFill size={20} className="ms-2" /></a>
                </Col>
                <Col>
                    <Nav className="social-media">
                        <Nav.Item>
                            <Nav.Link href="https://www.behance.net/vishalharia" target="_blank" rel="noopener noreferrer"><Behance size={28} className="behance" /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://www.linkedin.com/in/vishal-haria/" target="_blank" rel="noopener noreferrer"><Linkedin size={28} className="linkedin" /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://dribbble.com/vishalharia" target="_blank" rel="noopener noreferrer"><Dribbble size={28} className="dribbble" /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://twitter.com/iamvishalharia" target="_blank" rel="noopener noreferrer"><Twitter size={28} className="twitter" /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://medium.com/@iamvishalharia" target="_blank" rel="noopener noreferrer"><Medium size={28} className="medium" /></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <p className="copyright mt-5 pb-4 pt-1 pt-md-5 text-center">I made this site with ReactJS, Bootstrap &amp; Spline<br /><br />&copy; 2025 Vishal Haria</p>
        </Container>
    );
};
    
export default Footer;