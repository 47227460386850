import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { SEO } from '../components/seo';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import synrgetic from "../assets/case-study/synrgetic/mockups-1.jpg";
import metamask from "../assets/case-study/metamask/metamask-mockup-1.jpg";
import tamat from "../assets/case-study/tamat/mockups-1.jpg";
import larder from "../assets/case-study/larder/mockup-1.jpg";

import rodeo from "../assets/work/rodeo-finance.jpg";
import synrgeticFlutterApp from "../assets/work/synrgetic-flutter-app.jpg";
import synrgeticFWebsite from "../assets/work/synrgetic-website.jpg";
import hemkort from "../assets/work/hemkort-website.jpg";
import videography from "../assets/work/videography.jpg";

import ogimage from "../assets/vishalharia-website-screenshot.jpg";

//import Spline from '@splinetool/react-spline';

import styled from "styled-components";

//import { useLoadingContext } from "react-router-loading";

const Spline = React.lazy(() => import('@splinetool/react-spline'));

const Index = () => {
	//const loadingContext = useLoadingContext();
	//loadingContext.done(); //should happen after page content loads

	// Old spline
	// <Spline className='spline' scene="https://prod.spline.design/WSC7iyjQFMudVsOe/scene.splinecode" />

	return (
		<>
			<SEO
				title="UI/UX Designer in Bangkok - VishalHaria.net"
				description="UI/UX portfolio of Vishal Haria - a designer based in Bangkok, Thailand who loves to create beautiful designs."
				image={ogimage}
				url="https://vishalharia.net"
			/>

			<Container fluid className='position-relative'>
				<Wrapper className='d-none d-xl-block'>
					<Suspense>
						<Spline className='spline' scene="https://prod.spline.design/WSC7iyjQFMudVsOe/scene.splinecode" />
					</Suspense>
				</Wrapper>
				<Row>       
					<Col md={12} xl={7} className="cs-thumbnail-column">
						<div className='introduction'>
							<h1>Hi, I'm a UI/UX designer</h1>
							<h3>I also code, build websites/apps &amp; produce videos</h3>
						</div>
						<h5 className='grey-color'>Case Studies</h5>
						<Row className='mt-2 mt-md-4'>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${synrgetic})` }}>
									<Link to="/case-study/synrgetic">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Synrgetic</h2>
												<h4>mobile, app, ui/ux, concept</h4>
											</div>
										</div>
									</Link>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${metamask})` }}>
									<Link to="/case-study/metamask-apple-watch">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>MetaMask for Apple Watch</h2>
												<h4>watchOS, ui/ux, concept</h4>
											</div>
										</div>
									</Link>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${tamat})` }}>
									<Link to="/case-study/tamat">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Tamat</h2>
												<h4>responsive, website, ui/ux, concept</h4>
											</div>
										</div>
									</Link>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${larder})` }}>
									<Link to="/case-study/larder">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Larder</h2>
												<h4>mobile, app, ui/ux, concept</h4>
											</div>
										</div>
									</Link>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			<Container fluid className='position-relative work'>
				<Row>       
					<Col md={12} xl={7} className="cs-thumbnail-column">
						<h5 id='work' className='grey-color'>Work</h5>
						<Row className='mt-2 mt-md-4'>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${synrgeticFlutterApp})` }}>
									<a href="https://apps.apple.com/us/app/synrgetic-fitness-coaching/id6445988958" target="_blank" rel="noopener noreferrer">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Synrgetic Mobile App</h2>
												<h4>
													Designer / Developer
													<span className='badge rounded-pill text-bg-secondary ms-2'>2022 - 2023</span>
												</h4>
												<h5>
													<span className='badge rounded-pill text-bg-dark ms-2'>Flutter</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>Figma</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>PHP / Laravel</span>
												</h5>
											</div>
										</div>
									</a>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${rodeo})` }}>
									<a href="https://www.rodeofinance.xyz/" target="_blank" rel="noopener noreferrer">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Rodeo Finance</h2>
												<h4>
													UI/UX Lead Designer 
													<span className='badge rounded-pill text-bg-secondary ms-2'>2022 - 2023</span>
												</h4>
												<h5>
													<span className='badge rounded-pill text-bg-dark ms-2'>Figma</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>Midjourney</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>Photoshop</span>
												</h5>
											</div>
										</div>
									</a>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${synrgeticFWebsite})` }}>
									<a href="https://synrgetic.com/" target="_blank" rel="noopener noreferrer">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Synrgetic Website</h2>
												<h4>
													Designer / Developer
													<span className='badge rounded-pill text-bg-secondary ms-2'>2023</span>
												</h4>
												<h5>
													<span className='badge rounded-pill text-bg-dark ms-2'>Figma</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>HTML</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>CSS</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>JavaScript</span>
												</h5>
											</div>
										</div>
									</a>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${hemkort})` }}>
									<div className="image-overlay d-flex justify-content-center align-items-center rounded">
										<div className="text-center">
											<h2>Hemkört Website</h2>
											<h4>
												Designer / Developer
												<span className='badge rounded-pill text-bg-secondary ms-2'>In progress</span>
											</h4>
											<h5>
												<span className='badge rounded-pill text-bg-dark ms-2'>Figma</span>
												<span className='badge rounded-pill text-bg-dark ms-2'>HTML</span>
												<span className='badge rounded-pill text-bg-dark ms-2'>JavaScript</span>
												<span className='badge rounded-pill text-bg-dark ms-2'>PHP / Laravel</span>
											</h5>
										</div>
									</div>
								</div>
							</Col>
							<Col md={6} className="mb-4">
								<div className="case-study-thumbnail rounded" style={{ backgroundImage: `url(${videography})` }}>
									<a href="https://vimeo.com/vishalharia" target="_blank" rel="noopener noreferrer">
										<div className="image-overlay d-flex justify-content-center align-items-center rounded">
											<div className="text-center">
												<h2>Videography</h2>
												<h4>
													<span className='badge rounded-pill text-bg-secondary ms-2'>2019 - Present</span>
												</h4>
												<h5>
													<span className='badge rounded-pill text-bg-dark ms-2'>Premiere Pro</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>After Effects</span>
													<span className='badge rounded-pill text-bg-dark ms-2'>Drones</span>
												</h5>
											</div>
										</div>
									</a>
								</div>
							</Col>
						</Row>
					</Col>
					<Col md={12} xl={5}>
						<div className='d-flex w-100 h-100 justify-content-center align-items-top py-4 py-md-2 py-xl-4 px-0 px-md-4'>
							<div>
								<p className="larger-text">
									I have experience building websites and apps using:
								</p>
								<h3>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>HTML</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>CSS</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>JavaScript</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>PHP</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Flutter</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>ReactJS</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Bootstrap</span>
								</h3>
								<p className="larger-text mt-4">
									I film, edit and produce videos using:
								</p>
								<h3>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Adobe Premiere Pro</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Adobe After Effects</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Drones</span>
								</h3>
								<p className="larger-text mt-4">
									I do graphic design using:
								</p>
								<h3>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Adobe Photoshop</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Adobe Illustrator</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Figma</span>
									<span className='badge rounded-pill text-bg-secondary me-2 mb-2'>Adobe XD</span>
								</h3>
							</div>							
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

const Wrapper = styled.div`
	position: absolute;
	height: calc(100% - 102px);
	width: 60%;
	right: 0;

	@media(min-width:1400px){
		width: 50%;
	}

	.spline {
		position: absolute;

		@media(min-width:2240px){
			transform: scale(1.1) translateX(-60px);
		}

		@media(min-width:2656px){
			transform: scale(1.1) translateX(-80px);
		}
	}
`;

export default Index;
