import React from "react";
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../assets/vishalharia-brand-logo.png';
import { Download } from 'react-bootstrap-icons';

const TopMenu = () => {
    const downloadResume = () => {
        fetch('../downloads/vishal-haria-resume.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'vishal-haria-resume.pdf';
                alink.click();
            })
        })
    }
    return (
        <div className="top-menu" id="topMenu">
            <Navbar expand="lg">
                <Container fluid className="d-flex align-items-end top-menu-container">
                    <Link to="/" className="navbar-brand d-flex w-50 me-auto">
                        <img src={ logo } alt="Logo" width="300" height="60" className="d-none d-md-block" />
                        <img src={ logo } alt="Logo" width="240" height="48" className="d-block d-md-none" />
                    </Link>

                    <Navbar.Toggle className="mb-2" aria-controls="navbarNav" />

                    <Navbar.Collapse className="w-100" id="navbarNav">
                        <Nav className="w-100 ms-auto justify-content-end">
                            <Nav.Link as={ Link } to="/" className="nav-item">
                                Home
                            </Nav.Link>
                            <NavDropdown className="nav-item" title="Case Studies">
                                <NavDropdown.Item as={ Link } to="/case-study/synrgetic">
                                    Synrgetic
                                </NavDropdown.Item>
                                <NavDropdown.Item as={ Link } to="/case-study/metamask-apple-watch">
                                    MetaMask
                                </NavDropdown.Item>
                                <NavDropdown.Item as={ Link } to="/case-study/tamat">
                                    Tamat
                                </NavDropdown.Item>
                                <NavDropdown.Item as={ Link } to="/case-study/larder">
                                    Larder
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/#work" className="nav-item">
                                    Work
                            </Nav.Link>
                            <Nav.Link as={ Link } to="/about" className="nav-item">
                                About
                            </Nav.Link>
                            <Nav.Link as={ Link } onClick={downloadResume} className="nav-item">
                                Resume <Download className="ms-1" />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default TopMenu;
