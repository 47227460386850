import React from "react";
import { SEO } from '../../components/seo';

import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { ArrowUpCircleFill } from 'react-bootstrap-icons';
import { Download } from 'react-bootstrap-icons';

//images
import instagramIcon from "../../assets/instagram-icon.png";
import metamask from "../../assets/case-study/metamask/metamask-mockup-1.jpg";
import dualDevices from "../../assets/case-study/metamask/dual-devices.jpg";
import researchChart from "../../assets/case-study/metamask/research-chart.png";
import initialresearch from "../../assets/case-study/metamask/initial-research.jpg";
import flowchart from "../../assets/case-study/metamask/flow-chart.png";
import wireframes from "../../assets/case-study/metamask/wireframes.jpg";
import colourpalette from "../../assets/case-study/metamask/colour-palette.png";
import font from "../../assets/case-study/metamask/font.png";
import hifi from "../../assets/case-study/metamask/high-fidelity-designs.jpg";
import alignment from "../../assets/case-study/metamask/alignment.png";
import prototype from "../../assets/case-study/metamask/prototype.jpg";
import prototypeupdate1 from "../../assets/case-study/metamask/prototype-update.png";
import prototypeupdate2 from "../../assets/case-study/metamask/prototype-update-2.png";
import accessibility from "../../assets/case-study/metamask/accessibility-check.png";
import mockup3 from "../../assets/case-study/metamask/metamask-mockups-3.jpg";

const Metamask = () => {
    const downloadCaseStudy = () => {
        fetch('../../downloads/metamask-case-study.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'metamask-case-study.pdf';
                alink.click();
            })
        })
    }
    return (
        <>
            <SEO
                title="MetaMask for Apple Watch Case Study - VishalHaria.net"
                description="UI/UX case study for an Apple Watch version of the MetaMask app - a crypto wallet for Defi, web3 dapps and NFTs."
                image={metamask}
                url="https://vishalharia.net/case-study/metamask-apple-watch"
            />

            <Container fluid className="metamask px-0 js-scroll scrolled fade-in">
                <div className="d-flex justify-content-center">
                    <div className="cs-container position-relative">

                        <div id="topButton" className="position-fixed bottom-0 start-50 translate-middle-x top-button">
                            <ArrowUpCircleFill />
                        </div>

                        <img src={metamask} className="img-fluid" alt="MetaMask for Apple Watch" />

                        <section className="mb-5">
                            <h1>MetaMask for Apple Watch</h1>                            

                            <span className="larger-text">A watchOS app of the crypto wallet for Defi, Web3 Dapps & NFTs</span>

                            <div className="cs-badges">
                                <span className="cs-badge">Market research</span>
                                <span className="cs-badge">User survey</span>
                                <span className="cs-badge">Wireframes</span>
                                <span className="cs-badge">Flow diagrams</span>
                                <span className="cs-badge">High-fidelity UI</span>
                                <span className="cs-badge">Prototype</span>
                                <span className="cs-badge">Mini-usability study</span>
                            </div>

                            <div className="row cs-project-details">
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Where</dt>
                                        <dd>Bangkok, Thailand</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>What</dt>
                                        <dd>Apple Watch app</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Why</dt>
                                        <dd>Portfolio project</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>Designer, researcher</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Category</dt>
                                        <dd>Finance, cryptocurrency</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>When</dt>
                                        <dd>Oct 2022</dd>
                                    </dl>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-5 offset-lg-1 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                        <dl>
                                            <dt>Why I made this project</dt>
                                            <dd className="larger-text mt-1">
                                                I use MetaMask regularly for my crypto needs, as do their millions of users. 
                                            </dd>
                                            <dd className="larger-text">
                                                They started as a browser extension and currently have a mobile app, but do not have an app 
                                                for the Apple Watch yet - so I decided to design one.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={dualDevices} className="img-fluid js-scroll slide-right" alt="Dual devices" />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="color-bg">
                            <div className="row cs-project-details">
                                <div className="col-md-6">
                                    <h2>Market research</h2>
                                    <dl className="mt-4">
                                        <dt>The claim</dt>
                                        <dd>
                                        MetaMask's users have grown enormously from 545,080 in July 2020 to over 21 million in October 2022 - a 3,800% increase. 
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dd>
                                        Meanwhile, the Apple Watch has experienced sales increase from 8.3 million in 2015 to 46.1 million in 2021 - a 555% increase.
                                        </dd>
                                    </dl>                            
                                    
                                    <dl>
                                        <dt>The problem</dt>
                                        <dd>
                                        Even though there is currently a MetaMask app for mobile devices, there is not yet one for the Apple Watch. 
                                        </dd>
                                    </dl>
                                </div>
                                <div className="col-md-6">
                                    <img src={researchChart} className="img-fluid mt-3 mt-md-5 mb-5 js-scroll fade-in" alt="Market research" />
                                </div>
                            </div>
                        </section>

                        <section>
                            <h2>User survey</h2>
                            <p className="larger-text mt-4">
                                I conducted a very simple survey on social media for people who had previously stated they had some experience using MetaMask.
                            </p>
                            <div className="row mt-5 mb-4">
                                <div className="col-1 d-none d-md-block">
                                    <img src={instagramIcon} className="img-fluid mt-3" alt="Instagram icon" />
                                </div>
                                <div className="col-md-11">
                                    <h2 className="pt-0">
                                        Would you be interested in using a version of the MetaMask app for the Apple Watch?
                                    </h2>
                                    <p className="small mt-3">11 participants</p>

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Yes</span>
                                        <span>73%</span>
                                    </div>
                                    <ProgressBar now={73} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Not sure</span>
                                        <span>18%</span>
                                    </div>
                                    <ProgressBar now={18} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>No</span>
                                        <span>9%</span>
                                    </div>
                                    <ProgressBar now={9} className="mt-2 bg-white" />
                                </div>
                            </div>
                            
                            <h3 className="pt-5">Notable comments</h3>
                            <div className="row mt-2 pb-4">
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                                I'd be interested but I'm not sure how it would be able to fit on such a small screen.
                                            </dd>
                                            <dt>Tom</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                                It sounds useful for simple tasks like sharing my wallet address or making regular transfers.
                                            </dd>
                                            <dt>Chris</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                                It could be a good idea but there would need to be a way to prevent mistakes being made!
                                            </dd>
                                            <dt>Lotta</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="border-top border-3 mt-5 py-5">
                            <div className="row">
                                <div className="col-md-4 mt-4 mt-md-5 order-2 order-md-1">
                                    <img src={initialresearch} className="img-fluid js-scroll fade-in" alt="Initial research" />
                                </div>
                                <div className="col-md-8 order-1 order-md-2 mt-5 mt-md-0">                                
                                    <div className="h-100 d-flex align-items-center">
                                        <dl className="mt-3 mb-0">
                                            <dt>Initial research shows</dt>
                                            <dd className="larger-text mt-1">
                                                Both MetaMask and the Apple Watch are growing rapidly. It seems like a wasted opportunity that there is no version of the app on the Apple Watch.
                                            </dd>
                                            <dd className="larger-text mt-4 mb-5 mb-md-0">
                                                The goal of this project is to design the MetaMask app for watchOS and find out if it is something users who use both find useful.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="color-bg mt-3 pb-5">
                            <h2>Flow diagram</h2>
                            <p className="larger-text mt-4">
                                I created a flow diagram of the main user flow within the app, not including fail states in order to 
                                save screen space. Since there is already an app for MetaMask, most of the flows were derived from 
                                their existing app. Some modifications were made that made sense for such a small screen size of the 
                                Apple Watch, and some functionality was not included for the same reason.
                            </p>

                            <img src={flowchart} className="img-fluid mt-5" alt="Flow chart" />
                        </section>
                        
                        <section>
                            <h2>Low-fidelity wireframes</h2>
                            <p className="larger-text mt-4">
                                Due to the very small screen size, the tricky part was deciding what information should be displayed and how. 
                                Since apps on the Apple Watch are essentially an extension of their mobile app counterpart, functionality 
                                and data that was considered too complex for the small screen size was left out as users could switch to 
                                using a larger device for those tasks.
                            </p>
                            <p className="larger-text">
                                I made sure to follow Apple's Human Interface Guidelines to ensure the designs used their recommended 
                                best practices.
                            </p>
                            <img src={wireframes} className="img-fluid mb-4 js-scroll fade-in-bottom" alt="Wireframes" />
                        </section>

                        <section className="color-bg mt-3 pb-0">
                            <h2>High-fidelity UI design</h2>
                            <p className="larger-text mt-4">
                                Once the wireframes were designed, I moved on to creating high-fidelity designs, still strictly following 
                                Apple's guidelines. 
                            </p>
                            <div className="row cs-project-details mt-5">
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Colour palette</dt>
                                        <dd>
                                            Primary, secondary, tertiary, accent, background
                                        </dd>
                                    </dl>   
                                    <img src={colourpalette} className="img-fluid mb-5" alt="Colour palette" />
                                </div>
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Font</dt>
                                        <dd>
                                            SF Compact<br />
                                            Regular, semi-bold
                                        </dd>
                                    </dl>   
                                    <img src={font} className="img-fluid mb-5" alt="SF Compact font" />
                                </div>
                            </div>
                        </section>
                        
                        <section>
                            <h2>25 high-fidelity designs</h2>
                            <p className="larger-text mt-4">
                                I created a total of 25 high-fidelity designs that follows Apple's design guidelines. Many of the 
                                screens initially had different layouts than in the final designs as I wanted to create the simplest 
                                layouts with the limited screen space available. 
                            </p>
                        </section>
                        <img src={hifi} className="img-fluid mt-3 mb-5 js-scroll fade-in-bottom" alt="High-fidelity designs" />

                        <section className="color-bg mt-3 pb-0">
                            <h2>Alignment</h2>
                            <p className="larger-text mt-4">
                                For most screens, I set the margins within groups to be 0, 4 and 4, while the margins between groups 
                                to be 6, 10 and 12. On some screens where scrolling would not be possible, some margins needed to be 
                                modified so all elements could fit on the screen. Also, some margins are fixed due to Apple's design 
                                system.
                            </p>
                            <img src={alignment} className="img-fluid my-5 pb-3" alt="Alignment" />
                        </section>
                        
                        <section>
                            <h2>High-fidelity prototype</h2>
                            <p className="larger-text mt-4">
                                Using the high-fidelity designs I created, I built a working prototype that allows the user to experience 
                                the main user flows of the app. Some functionality wasn't included, such as typing numbers, so the screens 
                                with these features had these inputs pre-filled for demonstration purposes.
                            </p>
                        </section>
                        <img src={prototype} className="img-fluid my-5 js-scroll fade-in-bottom" alt="Prototype" />

                        <section className="pb-4">
                            <p className="larger-text">
                                The prototype can be live-previewed by clicking <a href="https://xd.adobe.com/view/5f62e304-90e7-46e5-9799-f5bb5fa1fee8-0a31/?fullscreen" target="_blank" rel="noreferrer">here</a>.
                            </p>

                            <h2 className="mt-4">Mini-usability study</h2>
                            <p className="larger-text mt-4">
                                I tested my prototype using 11 users who have some experience using the MetaMask browser extension and/or 
                                mobile app. Seeing as almost every watchOS app is a simpler version of a mobile app, I had the assumption 
                                that only people who have used the full version of the app would be interested in using this version, 
                                especially since its functionality is limited in comparison.
                            </p>
                            <p className="larger-text">
                                Each user were given tasks to complete - receive a transaction, send a transaction, and complete a swap. 
                                No other instructions were given apart from that. They were then instructed to give their feedback and 
                                state whether they were able to complete each of the tasks, and where they got stuck if they encountered 
                                any issues.
                            </p>
                            <p className="larger-text">
                                The study was conducted on social media through messages sent directly to each user.
                            </p>

                            <h2 className="mt-4">Study results</h2>
                            <p className="larger-text mt-4">
                                Surprisingly, each user was able to complete each of the tasks successfully. This could be due to the 
                                fact that they were already familiar with the full versions of the app, so the user flows in this app 
                                were predictable to them. 
                            </p>  
                                
                            <h3 className="pt-3">Notable comments</h3>
                            <div className="row mt-3 mb-5">
                                <div className="col-sm-6 mt-3">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            When I was sending an asset to an address, the address wasn't shown on the screen where 
                                            I had to input the amount. I could easily forget who I'm sending it to or make a mistake.
                                            </dd>
                                            <dt>Anita</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            Sometimes I didn't know if something was a button or just text. It would be good if they 
                                            were visibly different from one another.
                                            </dd>
                                            <dt>Tom</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-7">
                                    <h2>Prototype update</h2>
                                    <p className="larger-text mt-4">
                                        After receiving the feedback from the study, one of the changes I made was adding the selected 
                                        address that assets was being sent to the screen where the type of asset and the amount were entered. 
                                    </p>
                                    <p className="larger-text">
                                        This would make it clear to the user which address they were sending to, eliminating the need for 
                                        them to manually remember and preventing mistakes from being made.
                                    </p>
                                </div>
                                <div className="col-md-5">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={prototypeupdate1} className="img-fluid mt-3 mt-md-0 mb-0 js-scroll fade-in-bottom" alt="Prototype update 1" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-5">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={prototypeupdate2} className="img-fluid my-0 js-scroll fade-in-bottom" alt="Prototype update 2" />
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <p className="larger-text mt-4">
                                    Another change was to add a bright border around information that the user had selected and was important.
                                    </p>
                                    <p className="larger-text">
                                    This served two purposes - highlighting it as an area of importance, and also indicating it was not a button.
                                    </p>
                                </div>
                            </div>

                            <h2 className="mt-4">Second round of testing</h2>
                            <p className="larger-text mt-4">
                                After making a couple of changes to the designs and prototype as mentioned above, the same users were asked to 
                                complete the same tasks as before, noting any pain points they might have experienced and their overall feedback.
                            </p>
                            <p className="larger-text">
                                All users were able to successfully complete the tasks, but this time they had a noticeably more positive 
                                experience based on their feedback. A flaw with the study could be that because they had already tested 
                                the app, they remembered the main user flows so perhaps a better study would have been to have a new group 
                                of different users test the prototype and compare how their feedback compared to the first group.
                            </p>
                        </section>

                        <div className="color-bg mt-3 mb-4 pb-0"> 
                            <section>
                                <h2>Accessibility check</h2>
                                <p className="larger-text mt-4">
                                    Since this app was designed following Apple's strict guidelines, most of the accessibility concerns were about the choice 
                                    of colours used since the font and its size is set in the guidelines. Each colour was checked for contrast accessibility 
                                    according to the WCAG standards.
                                </p>
                            </section>                      
                            <img src={accessibility} className="img-fluid mt-4" alt="Accessibility check" />
                        </div>

                        <section className="pb-5 mb-3">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">     
                                    <dl className="mt-3 mb-3">
                                        <dt>Project summary</dt>
                                        <dd className="larger-text mt-1">
                                            The purpose of this project was to see if a version of the MetaMask app designed for the Apple Watch 
                                            would be useful and beneficial to its users. I conducted some basic market research and did a simple 
                                            survey on social media, designed some low-fidelity wireframes, and constructed a flow diagram of the 
                                            main user flows. Then I proceeded to create high-fidelity designs and an interactive prototype which 
                                            I used to conduct a mini-usability study with a group of users.
                                        </dd>

                                        <dd className="larger-text">
                                            I personally really enjoyed this project as I myself am a regular user of MetaMask and also have an 
                                            Apple Watch. I learned how functionality can be designed for such a small screen, which features 
                                            from the main version should be left out, and in the end I believe the final design is something 
                                            users would use and find useful.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </section>

                        <img src={mockup3} className="img-fluid" alt="MetaMask for Apple Watch mockups" />

                        <div className="text-center py-5">
                            <button onClick={downloadCaseStudy} className="btn btn-primary btn-blue">Download PDF <Download className="ms-1" /></button>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    );
};

export default Metamask;
