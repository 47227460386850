import React from 'react';
import { Helmet } from 'react-helmet-async';

export function SEO({title, description, image, url}) {
    return (
        <Helmet>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={url} />
            { /* End standard metadata tags */ }
            { /* Facebook tags */ }
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            { /* End Facebook tags */ }
            { /* Twitter tags */ }
            <meta name="twitter:creator" content="Vishal Haria" />
            <meta name="twitter:site" content="@iamvishalharia" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            { /* End Twitter tags */ }
        </Helmet>
    );
};