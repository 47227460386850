import React from "react";
import { SEO } from '../../components/seo';

import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { ArrowUpCircleFill } from 'react-bootstrap-icons';
import { Download } from 'react-bootstrap-icons';

//images
import larder from "../../assets/case-study/larder/mockup-1.jpg";
import dualDevices from "../../assets/case-study/larder/dual-devices.jpg";
import persona1 from "../../assets/case-study/larder/persona-1.jpg";
import persona2 from "../../assets/case-study/larder/persona-2.jpg";
import designprocess from "../../assets/case-study/larder/design-process.jpg";
import flowchart from "../../assets/case-study/larder/flow-chart.png";
import wireframes from "../../assets/case-study/larder/wireframes.jpg";
import colourpalette from "../../assets/case-study/larder/colour-palette.png";
import lines from "../../assets/case-study/larder/lines.png";
import hifi from "../../assets/case-study/larder/high-fidelity-designs.jpg";
import alignment from "../../assets/case-study/larder/alignment.png";
import prototype from "../../assets/case-study/larder/prototype.jpg";
import studyresults from "../../assets/case-study/larder/study-results.jpg";
import prototypeupdate from "../../assets/case-study/larder/prototype-update.jpg";
import accessibility from "../../assets/case-study/larder/accessibility-check.png";
import mockup2 from "../../assets/case-study/larder/mockup-2.jpg";

const Larder = () => {
    const downloadCaseStudy = () => {
        fetch('../../downloads/larder-case-study.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'larder-case-study.pdf';
                alink.click();
            })
        })
    }
    return (
        <>
            <SEO
                title="Larder Case Study - VishalHaria.net"
                description="UI/UX case study of a mobile table-reservation app for Larder, a local artisanal restaurant."
                image={larder}
                url="https://vishalharia.net/case-study/larder"
            />

            <Container fluid className="larder px-0 js-scroll scrolled fade-in">
                <div className="d-flex justify-content-center">
                    <div className="cs-container position-relative">

                        <div id="topButton" className="position-fixed bottom-0 start-50 translate-middle-x top-button">
                            <ArrowUpCircleFill />
                        </div>

                        <img src={larder} className="img-fluid" alt="Larder mobile app" />

                        <section className="mb-5">
                            <h1>Larder</h1>                            

                            <span className="larger-text">A table reservation app for a local artisanal restaurant</span>

                            <div className="cs-badges">
                                <span className="cs-badge">Customer survey</span>
                                <span className="cs-badge">Personas</span>
                                <span className="cs-badge">Flow diagrams</span>
                                <span className="cs-badge">Wireframes</span>
                                <span className="cs-badge">High-fidelity UI</span>
                                <span className="cs-badge">Prototype</span>
                                <span className="cs-badge">Mini-usability study</span>
                            </div>

                            <div className="row cs-project-details">
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Where</dt>
                                        <dd>Bangkok, Thailand</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>What</dt>
                                        <dd>Native mobile app</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Why</dt>
                                        <dd>Portfolio project, concept</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>Designer, researcher</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>Category</dt>
                                        <dd>Food, restaurant</dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <dl>
                                        <dt>When</dt>
                                        <dd>Nov 2022</dd>
                                    </dl>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-5 offset-lg-1 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                        <dl>
                                            <dt className="mt-md-5">Why I made this project</dt>
                                            <dd className="larger-text mt-1">
                                                Larder is a local restaurant to me and is owned by a friend of mine. They opened recently and have 
                                                been very successful, but are struggling with managing their table reservations.
                                            </dd>
                                            <dd className="larger-text">
                                            I wanted to see if I could find a solution to this problem.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <img src={dualDevices} className="img-fluid js-scroll slide-right" alt="Dual devices" />
                                </div>
                            </div>
                        </section>

                        <section className="color-bg mt-5 pb-4">
                            <h2>Customer survey</h2>
                            <p className="larger-text mt-4">
                                I put together a very simple survey that I asked several willing customers of Larder to fill out. The purpose 
                                was to determine if a table reservation app would be useful to its users.
                            </p>
                            <div className="row mt-4 mb-4">
                                <div className="col-sm-10 offset-sm-1">
                                    <h2 className="pt-4">
                                        Would you like to be able to reserve a table in advance?
                                    </h2>
                                    <p className="small mt-3">19 participants</p>

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Yes</span>
                                        <span>84%</span>
                                    </div>
                                    <ProgressBar now={84} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Not sure</span>
                                        <span>5%</span>
                                    </div>
                                    <ProgressBar now={5} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>No</span>
                                        <span>11%</span>
                                    </div>
                                    <ProgressBar now={11} className="mt-2 bg-white" />

                                    <h2 className="pt-5 mt-4">
                                        Do you currently use any apps or websites to make reservations?
                                    </h2>
                                    <p className="small mt-3">19 participants</p>

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>Yes</span>
                                        <span>79%</span>
                                    </div>
                                    <ProgressBar now={79} className="mt-2 bg-white" />

                                    <div className="d-flex justify-content-between larger-text fw-bold mt-4">
                                        <span>No</span>
                                        <span>21%</span>
                                    </div>
                                    <ProgressBar now={21} className="mt-2 bg-white" />
                                </div>
                            </div>
                            
                            <h3 className="pt-5">Notable comments</h3>
                            <div className="row mt-2 mb-5">
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            I have to wait a long time for a table - reservations would be great!
                                            </dd>
                                            <dt>Tony</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            I'm too old to queue up outside, but I'm not used to booking online.
                                            </dd>
                                            <dt>Padma</dt>
                                        </dl>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <div className="cs-review h-100">
                                        <dl>
                                            <dd>
                                            I'm always busy so being able to make reservations would be amazing.
                                            </dd>
                                            <dt>Constantin</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="py-5">
                            <h2>Personas</h2>
                            <p className="larger-text mt-4">
                                I created two personas based on the feedback I received from the customer survey in order 
                                to define user goals and frustrations.
                            </p>
                        </section>

                        <img src={persona1} className="img-fluid" alt="Persona 1" />

                        <img src={persona2} className="img-fluid mt-5" alt="Persona 2" />

                        <section>
                            <div className="row mt-5">
                                <div className="col-lg-5 offset-lg-1 col-md-6">
                                    <div className="h-100 d-flex align-items-center">
                                    <dl>
                                        <dt>The design process</dt>
                                        <dd className="larger-text mt-1">
                                        Now that the initial research stage was complete, it was time to create the 
                                        main user flows and low-fidelity wireframes.
                                        </dd>
                                    </dl>
            </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <img src={designprocess} className="img-fluid js-scroll slide-right" alt="Design process" />
                                </div>
                            </div>
                        </section>

                        <section className="color-bg mt-3 pb-5">
                            <h2>Flow diagram</h2>
                            <p className="larger-text mt-4">
                            I created a simple flow diagram of the main actions a user can take using the app to outline all of the 
                            necessary functionality. Fail state flows were created too, but are not shown due to space constraints. 
                            One of the main flows is shown below.
                            </p>

                            <img src={flowchart} className="img-fluid mt-5" alt="Flow chart" />
                        </section>
                        
                        <section>
                            <h2>Low-fidelity wireframes</h2>
                            <p className="larger-text mt-4">
                            After completing the user flow diagrams, I began creating the low-fidelity wireframes of the main user flows.
                            </p>
                            <img src={wireframes} className="img-fluid mb-4 js-scroll fade-in-bottom" alt="Wireframes" />
                        </section>

                        <section className="color-bg mt-3 pb-0">
                            <h2>High-fidelity UI design</h2>
                            <p className="larger-text mt-4">
                                After I had completed the main user flows and low-fidelity wireframes, I proceeded to start making 
                                high-fidelity designs, including selecting the fonts and colours to be used. 
                            </p>
                            <p className="larger-text">
                                Since Larder is already an established business with its own branding, I used the main colours from 
                                their existing designs so that the app fit their branding and would be instantly recognisable.
                            </p>
                            <div className="row cs-project-details mt-5">
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Colour palette</dt>
                                        <dd>
                                        Primary, secondary, tertiary, accent
                                        </dd>
                                    </dl>   
                                    <img src={colourpalette} className="img-fluid mb-5" alt="Colour palette" />
                                </div>
                                <div className="col-md-6">                                
                                    <dl className="mb-3">
                                        <dt>Font</dt>
                                        <dd>
                                        Plus Jakarta Sans<br />
                                        Regular, medium, semi-bold, bold, extra-bold
                                        </dd>
                                    </dl>   
                                    <h2 className="pt-0 mb-5 mb-md-0 plus-jakarta-sans">AaBbCcDdEeFfGgHh</h2>
                                </div>
                                <div className="col-md-6">                                
                                    <dl>
                                        <dt>Lined paper background</dt>
                                        <dd>
                                            Part of Larder's branding that they use for most of their media, is a white background with 
                                            horizontal and vertical lines, making it resemble lined paper. 
                                        </dd>
                                        <dd>
                                            I created an identical version to use on the main screens of the app to ensure consistency with their branding.
                                        </dd>
                                    </dl>   
                                </div>
                                <div className="col-md-6 text-center text-md-start">
                                    <img src={lines} className="img-fluid shadow w-75 mb-5" alt="Lined paper background" />
                                </div>
                            </div>
                        </section>
                        
                        <section>
                            <h2>16 high-fidelity designs</h2>
                            <p className="larger-text mt-4">
                                I created a total of 16 high-fidelity designs for this app since its functionality was relatively simple. A choice I made 
                                that impacted its complexity was only requiring a phone number when creating a user account, and dealing with signing in 
                                using a code that is sent via SMS to that number. This eliminates the need to remember access details, and also simplifies 
                                the overall design.
                            </p>
                        </section>
                        <img src={hifi} className="img-fluid mt-3 mb-5 js-scroll fade-in-bottom" alt="High-fidelity designs" />

                        <section className="color-bg mt-3 pb-0">
                            <h2>Alignment and grid</h2>
                            <p className="larger-text mt-4">
                                I made the margins within groups to be 8, 12 and 24, while the margins between groups to be 36 and 48.
                            </p>
                            <img src={alignment} className="img-fluid mt-3 mb-5" alt="Alignment and grid" />
                        </section>
                        
                        <section>
                            <h2>High-fidelity prototype</h2>
                            <p className="larger-text mt-4">
                                Using the high-fidelity designs I had created, I was then able to build a working prototype which could later be used for 
                                testing with a group of volunteers.
                            </p>
                        </section>
                        <img src={prototype} className="img-fluid my-3 js-scroll fade-in-bottom" alt="Prototype" />

                        <section className="pb-4">
                            <p className="larger-text">
                                The prototype can be live-previewed by clicking <a href="https://www.figma.com/proto/ortt2R2rWisif2pLKbKRQ2/Larder?node-id=1%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A2" target="_blank" rel="noreferrer">here</a>.
                            </p>

                            <h2 className="mt-5">Mini-usability study</h2>
                            <p className="larger-text mt-4">
                                Once the prototype was fully functional, I gathered a group of volunteers to test the app - many of which were customers who 
                                participated in the survey earlier on.
                            </p>
                            <p className="larger-text">
                                Each user were given a couple of tasks to complete: make a reservation, make another reservation and create an account, and 
                                finally to make a reservation after signing in. Task completion and click-paths were observed, and each user was asked for 
                                their feedback after they had finished. 11 people participated in the study.
                            </p>

                            <div className="row mt-5">
                                <div className="col-md-4">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={studyresults} className="img-fluid my-0 js-scroll fade-in-bottom" alt="Study results" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h2>Study results</h2>
                                    <p className="larger-text mt-4">
                                        73% of the users (8 out of 11) were able to complete all of the tasks successfully - the remaining 27% had some difficulty 
                                        signing into and creating their accounts as weren't aware they needed to request a code via SMS before trying to sign in.
                                    </p>
                                    <p className="larger-text">
                                        100% of the users managed to complete the first task of making a reservation, so I knew I was on the right track with the simple 
                                        user flow. The problem seemed to be making it clear to users that they needed to request a code for signing in before attempting 
                                        to sign in.
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-5">
                                    <h2>Prototype update</h2>
                                    <p className="larger-text mt-4">
                                        Based on the results of the study, I modified the sign-in overlay so the code input field only became 
                                        visible after a code was requested. This meant that the only button visible when the overlay is initially 
                                        displayed is the code request button.
                                    </p>
                                </div>
                                <div className="col-md-7">
                                    <div className="h-100 d-flex align-items-center">
                                        <img src={prototypeupdate} className="img-fluid my-0 js-scroll fade-in-bottom" alt="Prototype update" />
                                    </div>
                                </div>
                            </div>

                            <h2 className="mt-5">Second round of testing</h2>
                            <p className="larger-text mt-4">
                                Once I made changes to the sign-in overlay, I asked the users who joined the first round to participate in another. 
                                Unfortunately, not all of them were able to, but I managed to find some new users to take their places. This had the 
                                benefit of having some users who had never tried using the app before.
                            </p>
                            <p className="larger-text">
                                The results of the second study were better than the first, with every user being able to complete all of the tasks. 
                                However, some still made comments about the signing in process not being easy to understand as the code is sent via SMS 
                                but obviously they didn't receive one as it was a prototype. Whether this is a true pain point or if it was because no 
                                real SMS was sent, I'm not entirely sure but could be resolved with further testing using a simulated SMS being received 
                                or something similar.
                            </p>
                        </section>

                        <div className="color-bg mt-3 mb-4 pb-0"> 
                            <section>
                                <h2>Accessibility check</h2>
                                <p className="larger-text mt-4">
                                    Since I used Larder's branding colours in the app, I needed to check and make sure that they were accessible by comparing 
                                    them against the WCAG standards. 
                                </p>
                            </section>                      
                            <img src={accessibility} className="img-fluid mt-4" alt="Accessibility check" />
                        </div>

                        <section className="pb-5 mb-3">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">     
                                    <dl className="mt-3 mb-3">
                                        <dt>Project summary</dt>
                                        <dd className="larger-text mt-1">
                                            For this project, I conducted a short user survey in-person, created two personas based on the results, created 
                                            low-fidelity wireframes and user flows, made high-fidelity UI designs that I used to create a working prototype, 
                                            and iterated through the designs thanks to the mini-usability results. I made sure the colours used in the app 
                                            were accessible by checking them against the WCAG standards.
                                        </dd>

                                        <dd className="larger-text">
                                            Keeping the goals and frustrations of the personas in mind throughout the design process helped me focus on the 
                                            problems the app was trying to solve, which was to create an easy way for customers to reserve tables. From the 
                                            rounds of testing I did, I'm confident that the app has succeeded for the most part, though there may be room for 
                                            improvement with further testing.
                                        </dd>

                                        <dd className="larger-text">
                                            I enjoyed working on this project as it was relatively simple in terms of its functionality and the problem it was 
                                            trying to solve, but allowed for creative solutions such as the horizontal scrolling content for selecting the dates 
                                            and times, and also the SMS code for accessing a user account.
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </section>

                        <img src={mockup2} className="img-fluid" alt="Larder mockups" />

                        <div className="text-center py-5">
                            <button className="btn btn-primary btn-blue" onClick={downloadCaseStudy}>Download PDF <Download className="ms-1" /></button>
                        </div>
                    </div>
                </div>

            </Container>
        </>
    );
};

export default Larder;
